/* eslint-disable react/no-children-prop */
import React from 'react';
import { Observer, observer } from 'mobx-react';
import repositoryCss from './repository.css';

import {
    IconTextButton,
    SelectFormItem,
    AdvanceTable,
    IAdvanceTableToolbarProps,
    IBasicTableColumn,
    IBasicTableProps,
    Skeleton,
    showConfirmDialog,
    notification,
    Typography,
} from 'ui-lib';

import { useAuthStore, usePackageGroupsStore, usePackagesStore, useStores } from '../../../../store';
import { TDisplayPackageProps } from '../../../../store/packages-store';
import { TDisplayPackageGroupProps } from '../../../../store/package-groups-store';
import { IErrorProps, useRepositoryContext } from './index';
import { confirmCancel, confirmSave } from '../../admin/utils';
import { AccountType } from '../../../../dto/access-management/account-dto';
import { PACKAGE_TYPE, FILTER_PACKAGE_TYPE, PACKAGE_ARCH } from '../../../../dto/packages-dto';
import { cloneDeep } from 'lodash';
import advanceTableCss from '../../../../ui-lib/components/table/advance-table.css';
import { PackageRowComponent } from './package-utils';

function confirmDelete(pkg: TDisplayPackageProps, action: (pkg: TDisplayPackageProps) => Promise<void>): void {
    showConfirmDialog({
        title: 'Confirm Delete',
        content: `Do you want to remove the package "${pkg.label}"?`,
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: async () => action(pkg),
    });
}

export const PackagesWrapper = observer(() => {
    const context = useRepositoryContext();
    const packagesStore = usePackagesStore();
    const { configurationStore } = useStores();
    const packageGroupsStore = usePackageGroupsStore();
    const { currentUser, assureApiAccount } = useAuthStore();

    const [searchText, setSearchText] = React.useState<string>('');
    const [updatedRows, setUpdatedRows] = React.useState<{ id: number; packageGroupId: number }[]>([]);
    const displayDataSource = React.useMemo<TDisplayPackageProps[]>(() => {
        const dataSource = cloneDeep(packagesStore.displayPackages);
        for (const updatedRow of updatedRows) {
            const item = dataSource.find((item) => item.id == updatedRow.id);
            if (!item) continue;
            item.packageGroupId = updatedRow.packageGroupId;
        }
        return dataSource;
    }, [packagesStore.displayPackages, updatedRows]);

    const filteredDataSource = React.useMemo<TDisplayPackageProps[]>(
        () =>
            displayDataSource.filter((item) => item.label.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())),
        [displayDataSource, searchText],
    );

    const [updating, setUpdating] = React.useState<boolean>(false);

    const emptyPackageGroup: TDisplayPackageGroupProps = {
        id: -1,
        value: -1,
        label: ' ',
        type: currentUser.accountType as any,
        accountName: '',
        accountUuid: '',
        key: -1,
    };

    const clearUpdatedRows = () => {
        setUpdating(true);
        setUpdatedRows(() => []);
        setTimeout(() => setUpdating(false), 100);
    };

    const pkgGroupSelectComponentRenderer = (props: TDisplayPackageProps) => {
        const onChange = (value) => {
            setUpdatedRows((current) => {
                const row = current.find((item) => item.id == props.id);
                const packageGroupId = value;
                if (row) {
                    row.packageGroupId = packageGroupId;
                } else {
                    current.push({ id: props.id, packageGroupId });
                }
                return [...current];
            });
        };

        const dataSource = [emptyPackageGroup, ...packageGroupsStore.displayPackageGroups];

        return (
            <Skeleton loading={packageGroupsStore.dataLoading} noOfRows={1}>
                <SelectFormItem
                    onChange={onChange}
                    code={`package-${props.id}-packageGroup`}
                    label='PackageGroup'
                    dataSource={dataSource}
                    selectedValue={props.packageGroupId || -1}
                    disabled={
                        (currentUser.accountType === AccountType.CLIENT && props.type !== PACKAGE_TYPE.CLIENT) ||
                        (currentUser.accountType === AccountType.VAR &&
                            ![PACKAGE_TYPE.CLIENT, PACKAGE_TYPE.VAR].includes(props.type))
                    }
                />
            </Skeleton>
        );
    };

    const deletePackage = async (pkg: TDisplayPackageProps) => {
        try {
            await packagesStore.deletePackage(assureApiAccount.accountId, pkg.id);
        } catch (err) {
            notification.error({
                message: 'Delete package error',
                description: <Typography.Text>{err.message}</Typography.Text>,
            });
        }
    };

    const handleSave = async () => {
        const editList = updatedRows;
        const updatedErrors: IErrorProps[] = [];
        try {
            await Promise.all(
                editList.map(async (item) => {
                    try {
                        return await packagesStore.updateGroup(assureApiAccount.accountId, {
                            packageId: item.id,
                            packageGroupId: item.packageGroupId,
                        });
                    } catch (err) {
                        updatedErrors.push({
                            key: filteredDataSource.find((entity) => entity.id == item.id)?.label,
                            message: err.message,
                        });
                    }
                }),
            );
            if (updatedErrors.length > 0) {
                notification.error({
                    message: 'Update package errors',
                    description: updatedErrors.map((err) => (
                        <Typography.Text key={err.key}>
                            {err.key}: {err.message}
                        </Typography.Text>
                    )),
                });
            }
        } finally {
            clearUpdatedRows();
        }
    };

    const columns: IBasicTableColumn[] = [
        {
            code: 'packageName',
            title: 'Name',
            dataIndex: 'label',
            width: '25%',
            sorter: (a: TDisplayPackageProps, b: TDisplayPackageProps) => a.label?.localeCompare(b.label),
            render: (text: string, record: TDisplayPackageProps): React.ReactNode => {
                return <PackageRowComponent configurationStore={configurationStore} pkg={record}/>;
            },
        },
        {
            code: 'version',
            title: 'Version',
            dataIndex: 'version',
            width: '5%',
        },
        {
            code: 'packageGroupName',
            title: 'Group name',
            dataIndex: 'packageGroupName',
            width: '30%',
            render: (_, record: TDisplayPackageProps) => (
                <Observer>{() => pkgGroupSelectComponentRenderer(record)}</Observer>
            ),
        },
        {
            code: 'architecture',
            title: 'Architecture',
            dataIndex: 'architecture',
            width: '10%',
            sorter: (a: TDisplayPackageProps, b: TDisplayPackageProps) => a.architecture?.localeCompare(b.architecture),
            filters: Object.keys(PACKAGE_ARCH).map((arch) => ({ text: PACKAGE_ARCH[arch], value: PACKAGE_ARCH[arch] })),
            onFilter: (architecture, pkg) => pkg.architecture == architecture,
        },
        {
            code: 'type',
            title: 'Type',
            dataIndex: 'type',
            width: '5%',
            sorter: (a: TDisplayPackageProps, b: TDisplayPackageProps) => a.type?.localeCompare(b.type),
            filters: Object.keys(FILTER_PACKAGE_TYPE).map((type) => ({ text: FILTER_PACKAGE_TYPE[type], value: type })),
            onFilter: (type, device) => device.type.toLowerCase() === type.toLowerCase(),
        },
        ...(currentUser.accountType !== AccountType.CLIENT
            ? [
                  {
                      code: 'accountName',
                      title: 'VAR/Client name',
                      dataIndex: 'accountName',
                      sorter: (a, b) => a.accountName?.localeCompare(b.accountName),
                      width: '20%',
                  },
              ]
            : []),
        {
            code: 'updatedAt',
            title: 'Updated date',
            dataIndex: 'updatedAt',
            width: '30%',
            defaultSortOrder: 'descend',
            sorter: (a: TDisplayPackageProps, b: TDisplayPackageProps) =>
                a.updatedAtTimestamp?.localeCompare(b.updatedAtTimestamp),
        },
        {
            code: 'action',
            title: 'Action',
            dataIndex: 'action',
            width: '10%',
            render: (_, record: TDisplayPackageProps) => (
                <IconTextButton
                    key='id'
                    label='Delete'
                    type='link'
                    onClick={() => confirmDelete(record, deletePackage)}
                    disabled={
                        (currentUser.accountType === AccountType.CLIENT && record.type !== PACKAGE_TYPE.CLIENT) ||
                        (currentUser.accountType === AccountType.VAR &&
                            ![PACKAGE_TYPE.CLIENT, PACKAGE_TYPE.VAR].includes(record.type))
                    }
                />
            ),
        },
    ];

    const tableProps: IBasicTableProps = {
        columns: columns,
        dataSource: filteredDataSource,
    };

    const toolbar: IAdvanceTableToolbarProps = {
        onSearch: setSearchText,
        onReload: () => packagesStore.loadEntities(assureApiAccount.accountId, true), // force load data
        searchPlaceholder: 'Package name',
        extraItems: [
            <IconTextButton
                key='uploadPackages'
                label='Upload packages'
                type='primary'
                onClick={context.toUploadPackage}
                iconName={'upload'}
            />,
            ' ',
            <IconTextButton
                key='manageGroups'
                label='Manage package groups'
                type='primary'
                onClick={context.toPackageGroupsTable}
                iconName={'table-list'}
                className={advanceTableCss.extraSpacing}
            />,
        ],
    };

    return (
        <React.Fragment>
            <AdvanceTable
                title='All packages'
                className={repositoryCss.table}
                table={tableProps}
                loadingState={packagesStore.dataLoading || updating}
                toolbar={toolbar}
            />
            <div className={repositoryCss.row}>
                <IconTextButton key='cancel' label='Cancel' onClick={() => confirmCancel(clearUpdatedRows)} />
                <IconTextButton
                    key='save'
                    label='Save'
                    type='primary'
                    onClick={() => confirmSave(undefined, 'Do you want to save your change?', handleSave)}
                />
            </div>
        </React.Fragment>
    );
});
